
import { reversedash } from "@/filters/utils";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class IDatePicker extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: string | Date;

  public activePicker = "";
  public date = "";
  public menu = false;

  @Emit("input")
  save(date: string): Date {
    this.menu = false;
    return new Date(date);
  }

  @Watch("value")
  observeValue(): void {
    const df = reversedash(this.value);
    if (this.date !== df) {
      this.date = df;
    }
  }
}
